import {
  Grid,
  Box,
  TableContainer,
  Paper,
  Button,
  TextField,
  MenuItem,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
  FormControl,
  CircularProgress,
  Chip,
  Autocomplete,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  TableComponent,
  IconButton as CIconButton,
  PageTitle,
} from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";
import { useQuery, useMutation, useQueryClient } from "react-query";
import DialogAppBar from "./DialogAppBar";
import CloseIcon from "@mui/icons-material/Close";
import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";
import EditIcon from "@mui/icons-material/Edit";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RetailerPriceProfileTab = ({ retailerId, prerequisites }) => {
  const theme = useTheme();
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [carrierId, setCarrierId] = useState(undefined);
  const [parcelFee, setParcelFee] = useState(0);
  const [cogs, setCogs] = useState(0);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [extraFees, setExtraFees] = useState({});
  const [priceProfileId, setPriceProfileId] = useState(undefined);

  const retailer = prerequisites?.retailers?.find((r) => r.id === retailerId);
  const carriers = retailer?.carriers || [];
  const statuses = prerequisites?.statuses || [];

  const handleOpenDialog = () => {
    clear();
    setNewDialogOpen(true);
  };

  const clear = () => {
    setCarrierId(null);
    setParcelFee(0);
    setCogs(0);
    setSelectedStatuses([]);
    setPriceProfileId(null);
    setExtraFees({});
  };

  const handleSave = () => {
    const data = {
      carrierId,
      parcelFee: Number(parcelFee),
      cogs: Number(cogs),
      retailerId,
      statuses: selectedStatuses.map((status) => status.id),
      extraFees: extraFees,
    };
    if (priceProfileId) {
      updatePriceProfileMutation.mutate({ ...data, id: priceProfileId });
    } else {
      createPriceProfileMutation.mutate(data);
    }
  };

  const handleFeeChange = (e) => {
    const { id, value } = e.target;
    setExtraFees((prev) => ({ ...prev, [id]: value }));
  };

  const { data: priceProfiles, refetch } = useQuery(
    ["price-profile", retailerId],
    async () => {
      const result = await getRequestUI(`retailer/${retailerId}/price-profile`);
      return result;
    },
  );

  const createPriceProfileMutation = useMutation(
    (data) => postRequestUI(`retailer/price-profile/${retailerId}`, data),
    {
      onSuccess: () => {
        refetch();
        setNewDialogOpen(false);
      },
      onError: (error) => {
        console.error("Failed to save the price profile:", error);
      },
    },
  );

  const updatePriceProfileMutation = useMutation(
    (data) => putRequestUI(`retailer/price-profile/${data.id}`, data),
    {
      onSuccess: () => {
        refetch();
        setNewDialogOpen(false);
      },
      onError: (error) => {
        console.error("Failed to update the price profile:", error);
      },
    },
  );

  const editProfile = (profile) => {
    setCarrierId(profile.carrierId);
    setParcelFee(profile.parcelFee);
    setCogs(profile.cogs);
    setSelectedStatuses(
      profile.statuses.map((status) =>
        statuses.find((s) => s.id === status.id),
      ),
    );
    const newExtraFees = {};
    [...profile.additionalCOGSFees, ...profile.additionalParcelFees].forEach(
      (fee) => {
        newExtraFees[fee.id] = fee.value;
      },
    );
    setExtraFees(newExtraFees);
    setPriceProfileId(profile.id);
    setNewDialogOpen(true);
  };

  const deletePriceProfileMutation = useMutation(
    (profileId) => deleteRequestUI(`retailer/price-profile/${profileId}`),
    {
      onSuccess: () => {
        useQueryClient.invalidateQueries(["price-profile", retailerId]);
        refetch();
      },
      onError: (error) => {
        console.error("Failed to delete the price profile:", error);
      },
    },
  );

  const handleDeleteProfile = (profileId) => {
    deletePriceProfileMutation.mutate(profileId);
  };
  return (
    <>
      <Grid container>
        <TableContainer component={Paper}>
          <div style={{ padding: 15 }}>
            <PageTitle setTitle={"Price Profiles"} />
          </div>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleOpenDialog}
          >
            Add Price Profile
          </Button>
          <TableComponent
            headers={["Carrier", "Parcel Fee", "COGS", "Statuses", "Actions"]}
            rowData={priceProfiles || []}
            cellComponents={[
              (profile) =>
                carriers.find((c) => c.id === profile.carrierId)?.name ||
                "Unknown",
              (profile) => profile.parcelFee,
              (profile) => profile.cogs,
              (profile) => (
                <>
                  {profile.statuses.map((status) => (
                    <Chip key={status.id} label={status.name} />
                  ))}
                </>
              ),
              (profile) => (
                <>
                  <CIconButton
                    icon={<EditIcon />}
                    onClick={() => editProfile(profile)}
                  />

                  <CIconButton
                    icon="delete"
                    onClick={() => handleDeleteProfile(profile.id)}
                  />
                </>
              ),
            ]}
          />
        </TableContainer>

        {newDialogOpen && (
          <Dialog
            fullScreen
            open={newDialogOpen}
            onClose={() => setNewDialogOpen(false)}
            TransitionComponent={Transition}
          >
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setNewDialogOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button autoFocus color="inherit" onClick={handleSave}>
                  {priceProfileId ? "Update" : "Save"}
                </Button>
              </Toolbar>
            </DialogAppBar>

            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {priceProfileId ? "Edit Price Profile" : "Add Price Profile"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Select Carrier"
                    value={carrierId}
                    onChange={(e) => setCarrierId(e.target.value)}
                    margin="dense"
                    size="small"
                  >
                    {carriers.length > 0 ? (
                      carriers.map((carrier) => (
                        <MenuItem key={carrier.id} value={carrier.id}>
                          {carrier.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No carriers available</MenuItem>
                    )}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Parcel Fee"
                    value={parcelFee}
                    onChange={(e) => setParcelFee(e.target.value)}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    label="COGS"
                    value={cogs}
                    onChange={(e) => setCogs(e.target.value)}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    options={statuses}
                    getOptionLabel={(option) => option.name}
                    value={selectedStatuses}
                    onChange={(event, newValue) =>
                      setSelectedStatuses(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Statuses"
                        margin="dense"
                        size="small"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Additional Parcel Fees
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="weekend_fee"
                    label="Weekend Fee"
                    value={extraFees.weekend_fee}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="xl_parcel_fee"
                    label="XL Parcel Fee"
                    value={extraFees.xl_parcel_fee}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="insurance_fee"
                    label="Insurance Fee"
                    value={extraFees.insurance_fee}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="signature_fee"
                    label="Signature Fee"
                    value={extraFees.signature_fee}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="no_neighbor_fee"
                    label="No Neighbor Fee"
                    value={extraFees.no_neighbor_fee}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="age_check_fee"
                    label="Age Check Fee"
                    value={extraFees.age_check_fee}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Additional COGS Fees
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="signature_cogs"
                    label="Signature COGS"
                    value={extraFees.signature_cogs}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="no_neighbor_cogs"
                    label="No Neighbor COGS"
                    value={extraFees.no_neighbor_cogs}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    id="age_check_cogs"
                    label="Age Check COGS"
                    value={extraFees.age_check_cogs}
                    onChange={handleFeeChange}
                    margin="dense"
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Dialog>
        )}
      </Grid>
    </>
  );
};

export default RetailerPriceProfileTab;
